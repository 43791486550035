<template>
  <v-container grid-list-xl text-md-center>

    <!-- Title -->
    <v-layout row justify-center>
      <v-flex md12>
        <v-card-title primary-title class="justify-center">
          <h3 class="text--accent-2">Presse Artikel</h3>
        </v-card-title>
      </v-flex>
    </v-layout>

    <!-- Actual Article Loop -->
    <v-layout row justify-center fluid fill-height>
      <v-flex xs12 sm12 md3 v-for="article in getArticles()" :key="article.source">
        <v-hover v-slot:default="{ hover }">
          <v-card
            align="center"
            justify="center"
            outlined
            :class="{ 'on-hover': hover }"
            :href="article.source"
            target="_blank"
          >
            <v-card-subtitle
              justify-space-between
              class="accent"
              >
              {{ article.title }}
            </v-card-subtitle>
          </v-card>
        </v-hover>
      </v-flex>
    </v-layout>

    <!-- Title -->
    <v-layout row justify-center>
      <v-flex md12>
        <v-card-title primary-title class="justify-center">
          <h3 class="text--accent-2">Links</h3>
        </v-card-title>
      </v-flex>
    </v-layout>

    <!-- Actual Link Loop -->
    <v-layout row justify-center fluid fill-height>
      <v-flex xs12 sm12 md3 v-for="link in getLinks()" :key="link.url">
        <v-hover v-slot:default="{ hover }">
          <v-card
            align="center"
            justify="center"
            outlined
            :class="{ 'on-hover': hover }"
            :href="link.url"
            target="_blank"
          >
            <v-card-subtitle
              justify-space-between
              class="accent"
              >
              {{ link.title }}
            </v-card-subtitle>
          </v-card>
        </v-hover>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
export default {
  name: 'ShowPress',
  components: {
  },
  data () {
    return {
      baseurl: process.env.VUE_APP_API,
    }
  },
  methods: {
    getArticles: function () {
      return [
        {
          title: 'CAL 2014 Tageblatt',
          source: this.baseurl + '/assets/articles/Tageblatt_CAL2014.pdf',
        },
        {
          title: 'Bloom award "Art Fair"',
          source: this.baseurl + '/assets/articles/BLOOOM_Finalisten_Werke.pdf',
        },
        {
          title: 'Cercle Artistique',
          source: this.baseurl + '/assets/articles/Salon_du_cercle_artistique.pdf',
        },
        {
          title: 'Kunstmundig',
          source: this.baseurl + '/assets/articles/Kunstmundig.pdf',
        },
      ]
    },

    getLinks: function() {
      return [
        {
          title: 'Salon CAL 2014 RTL.lu',
          url: 'http://tele.rtl.lu/emissiounen/de-journal/3024429.html',
        },
        {
          title: 'Klasse Orosz',
          url: 'http://www.klasse-orosz.de/portfolio/katrin-elsen/',
        },
        {
          title: 'Symbol für Kraft und Fruchtbarkeit',
          url: 'http://www.tageblatt.lu/nachrichten/luxemburg/story/Symbol-f--r-Kraft-und-Fruchtbarkeit-30036947',
        },
        {
          title: 'Die Kunstagentin Blooom Award',
          url: 'http://www.diekunstagentin.de/blooom-award-ueberraschung/',
        },
        {
          title: 'Blooom Award by Warsteiner',
          url: 'http://www.blooomawardbywarsteiner.de/TOP_100.html',
        }
      ]
    }
  }
}
</script>
